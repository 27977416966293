import { keyframes } from '@emotion/react';

const flicker = keyframes`
  0% {
    opacity: 0.97;
  }
  2% {
    opacity: 0.9;
  }
  8% {
    opacity: 0.97;
  }
  70% {
    opacity: 0.98;
  }
  72% {
    opacity: 0.9;
  }
  74% {
    opacity: 0.98;
  }
  100% {
    opacity: 0.97;
  }
`;

export const crtEffect = {
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `
      linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.1) 50%),
      linear-gradient(90deg, rgba(255, 0, 0, 0.03), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.03))
    `,
    backgroundSize: '100% 2px, 3px 100%',
    pointerEvents: 'none',
    animation: `${flicker} 4s infinite`,
    zIndex: 2,
  },
  '&::after': {
    content: '""',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'radial-gradient(circle at center, transparent 60%, rgba(0, 0, 0, 0.4) 100%)',
    pointerEvents: 'none',
    zIndex: 1,
  },
};

export const chromaticText = {
  position: 'relative',
  display: 'inline-block',
  color: '#ffffff',
  mixBlendMode: 'screen',
  animation: 'textflicker 1.5s infinite',
  '@keyframes textflicker': {
    '0%': { textShadow: '0.4389924193300864px 0 1px rgba(0,30,255,0.5), -0.4389924193300864px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '5%': { textShadow: '2.7928974010788217px 0 1px rgba(0,30,255,0.5), -2.7928974010788217px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '10%': { textShadow: '0.02956275843481219px 0 1px rgba(0,30,255,0.5), -0.02956275843481219px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '15%': { textShadow: '0.40218538552878136px 0 1px rgba(0,30,255,0.5), -0.40218538552878136px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '20%': { textShadow: '3.4794037899852017px 0 1px rgba(0,30,255,0.5), -3.4794037899852017px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '25%': { textShadow: '1.6125630401149584px 0 1px rgba(0,30,255,0.5), -1.6125630401149584px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '30%': { textShadow: '0.7015590085143956px 0 1px rgba(0,30,255,0.5), -0.7015590085143956px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '35%': { textShadow: '3.896914047650351px 0 1px rgba(0,30,255,0.5), -3.896914047650351px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '40%': { textShadow: '3.870905614848819px 0 1px rgba(0,30,255,0.5), -3.870905614848819px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '45%': { textShadow: '2.231056963361899px 0 1px rgba(0,30,255,0.5), -2.231056963361899px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '50%': { textShadow: '0.08084290417898504px 0 1px rgba(0,30,255,0.5), -0.08084290417898504px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '55%': { textShadow: '2.3758461067427543px 0 1px rgba(0,30,255,0.5), -2.3758461067427543px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '60%': { textShadow: '2.202193051050636px 0 1px rgba(0,30,255,0.5), -2.202193051050636px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '65%': { textShadow: '2.8638780614874975px 0 1px rgba(0,30,255,0.5), -2.8638780614874975px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '70%': { textShadow: '0.48874025155497314px 0 1px rgba(0,30,255,0.5), -0.48874025155497314px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '75%': { textShadow: '1.8948491305757957px 0 1px rgba(0,30,255,0.5), -1.8948491305757957px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '80%': { textShadow: '0.0833037308038857px 0 1px rgba(0,30,255,0.5), -0.0833037308038857px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '85%': { textShadow: '0.09769827255241735px 0 1px rgba(0,30,255,0.5), -0.09769827255241735px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '90%': { textShadow: '3.443339761481782px 0 1px rgba(0,30,255,0.5), -3.443339761481782px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '95%': { textShadow: '2.1841838852799786px 0 1px rgba(0,30,255,0.5), -2.1841838852799786px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
    '100%': { textShadow: '2.6208764473832513px 0 1px rgba(0,30,255,0.5), -2.6208764473832513px 0 1px rgba(255,0,80,0.3), 0 0 3px' },
  },
};

// Animation variants for Framer Motion
export const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const itemVariants = {
  hidden: { 
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 12,
    },
  },
};

export const glowingBorder = {
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 'inherit',
    padding: '1px',
    background: 'linear-gradient(135deg, #64DFDF, #B298DC)',
    mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    maskComposite: 'exclude',
    WebkitMaskComposite: 'xor',
    opacity: 0.3,
    transition: 'all 0.3s ease-in-out',
  },
  '&:hover::before': {
    opacity: 0.5,
    padding: '1.5px',
  },
};

export const scanlines = {
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'repeating-linear-gradient(transparent 0px, rgba(100, 223, 223, 0.01) 1px, transparent 2px)',
    pointerEvents: 'none',
    opacity: 0.2,
    animation: 'scanline 8s linear infinite',
  },
  '@keyframes scanline': {
    '0%': {
      transform: 'translateY(0)',
    },
    '100%': {
      transform: 'translateY(100px)',
    },
  },
};

export const fadeInUp = {
  animation: 'fadeInUp 0.6s ease-out forwards',
  opacity: 0,
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
};

export const staggerChildren = (startDelay = 0) => ({
  '& > *': {
    animation: 'fadeInUp 0.6s ease-out forwards',
    opacity: 0,
  },
  ...Array.from({ length: 10 }, (_, i) => ({
    [`& > *:nth-of-type(${i + 1})`]: {
      animationDelay: `${startDelay + i * 0.1}s`,
    },
  })).reduce((acc, val) => ({ ...acc, ...val }), {}),
});

export const glitch = {
  position: 'relative',
  '&:hover': {
    '&::before': {
      content: 'attr(data-text)',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      background: '#0B0C10',
      opacity: 0.7,
      animation: 'glitch 0.4s cubic-bezier(.17,.67,.83,.67)',
      animationIterationCount: 2,
    },
  },
  '@keyframes glitch': {
    '0%': {
      transform: 'translate(0)',
    },
    '20%': {
      transform: 'translate(-1px, 1px)',
    },
    '40%': {
      transform: 'translate(-1px, -1px)',
    },
    '60%': {
      transform: 'translate(1px, 1px)',
    },
    '80%': {
      transform: 'translate(1px, -1px)',
    },
    '100%': {
      transform: 'translate(0)',
    },
  },
}; 