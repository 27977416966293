import React from 'react';
import { 
  Container, 
  Typography, 
  Box,
  Paper,
} from '@mui/material';
import { motion } from 'framer-motion';
import PageLayout from '../components/PageLayout';
import { itemVariants, chromaticText } from '../styles/effects';
import SEO from '../components/SEO';

const MotionPaper = motion(Paper);

const ChromaticTypography = ({ children, ...props }) => (
  <Typography
    {...props}
    component="div"
    sx={{
      ...chromaticText,
      ...props.sx,
    }}
    data-text={children}
  >
    {children}
  </Typography>
);

function Impressum() {
  return (
    <PageLayout>
      <SEO 
        title="Impressum"
        description="Legal information and company details"
      />
      <Container maxWidth="md" sx={{ py: { xs: 4, md: 8 } }}>
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <motion.div variants={itemVariants}>
            <ChromaticTypography
              variant="h1"
              sx={{
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                mb: 2,
              }}
            >
              Impressum
            </ChromaticTypography>
          </motion.div>
        </Box>

        <MotionPaper
          variants={itemVariants}
          sx={{
            p: { xs: 3, md: 4 },
            background: 'rgba(255, 255, 255, 0.03)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.05)',
          }}
        >
          <Typography variant="body1" paragraph>
            Adrian Lohr
            <br />
            Lohr Software
            <br />
            Arnheidstr. 3
            <br />
            13467 Berlin
            <br />
            Deutschland
            <br />
            <br />
            Tel.: +4915228639973
            <br />
            E-Mail: <a href="mailto:mail@lohrsoftware.com" style={{ color: '#64DFDF', textDecoration: 'none' }}>mail@lohrsoftware.com</a>
            <br />
            <br />
            Umsatzsteuer-Identifikationsnummer: DE400003299
            <br />
            Umsatzsteuerbefreit (Kleinunternehmerregelung)
            <br />
            <br />
            Plattform der EU-Kommission zur Online-Streitbeilegung: https://ec.europa.eu/odr
            <br />
            <br />
            Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
          </Typography>
        </MotionPaper>
      </Container>
    </PageLayout>
  );
}

export default Impressum;
