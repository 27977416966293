import React from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import { crtEffect, containerVariants } from '../styles/effects';

const MotionBox = motion(Box);

const PageLayout = ({ children }) => {
  return (
    <Box sx={{ ...crtEffect }}>
      <MotionBox
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </MotionBox>
    </Box>
  );
};

export default PageLayout; 