import React from 'react';
import { Box, Typography, Button, Container, Stack, Divider, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box 
      component="footer" 
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: 'background.paper',
        borderTop: 1,
        borderColor: 'divider',
      }}
    >
      <Container maxWidth="lg">
        <Stack
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems={isMobile ? 'center' : 'flex-start'}
          spacing={2}
          divider={isMobile ? <Divider flexItem /> : null}
        >
          <Stack spacing={1} alignItems={isMobile ? 'center' : 'flex-start'}>
            <Typography variant="body2" color="text.secondary" align={isMobile ? 'center' : 'left'}>
              Apple, Apple logo and macOS are trademarks of Apple Inc., registered in the U.S. and other countries.
            </Typography>
            <Typography variant="body2" color="text.secondary" align={isMobile ? 'center' : 'left'}>
              &copy; {new Date().getFullYear()} Lohr Software. All Rights Reserved.
            </Typography>
          </Stack>

          <Stack 
            direction={isMobile ? 'column' : 'row'} 
            spacing={2}
            alignItems="center"
          >
            <Button
              component={Link}
              to="/impressum"
              size="small"
              sx={{ 
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              Impressum
            </Button>
            <Button
              component={Link}
              to="/privacypolicy"
              size="small"
              sx={{ 
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              Privacy Policy
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default Footer;
