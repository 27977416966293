import React from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  Box,
} from '@mui/material';
import { motion } from 'framer-motion';
import PageLayout from '../components/PageLayout';
import { itemVariants, chromaticText } from '../styles/effects';
import SEO from '../components/SEO';

const MotionGrid = motion(Grid);
const MotionCard = motion(Card);

const apps = [
  {
    title: 'Billable Hours',
    description: 'Billable Hours is a simple yet powerful app designed for freelancers and small businesses to track their time and generate professional invoices. Keep track of your working hours with ease and never lose out on billable time again.',
    appStoreLink: 'https://apps.apple.com/app/xxxx',
  },
  {
    title: 'File Morpher',
    description: 'File Morpher is a versatile app for transforming files into different formats. Whether you need to convert images, documents, or other files, File Morpher is the perfect tool for seamless file conversion.',
    appStoreLink: 'https://apps.apple.com/app/xxxx',
  },
];

const ChromaticTypography = ({ children, ...props }) => (
  <Typography
    {...props}
    component="div"
    sx={{
      ...chromaticText,
      ...props.sx,
    }}
    data-text={children}
  >
    {children}
  </Typography>
);

function Apps() {
  return (
    <PageLayout>
      <SEO 
        title="Our Apps"
        description="Discover our collection of professional macOS applications"
      />
      <Container maxWidth="lg" sx={{ py: { xs: 4, md: 8 } }}>
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <motion.div variants={itemVariants}>
            <ChromaticTypography
              variant="h1"
              sx={{
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                mb: 2,
              }}
            >
              Our Applications
            </ChromaticTypography>
          </motion.div>
          <motion.div variants={itemVariants}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '1.1rem', sm: '1.25rem' },
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Professional tools designed to enhance your macOS experience
            </Typography>
          </motion.div>
        </Box>

        <MotionGrid container spacing={4}>
          {apps.map((app, index) => (
            <Grid item xs={12} md={6} key={index}>
              <MotionCard
                variants={itemVariants}
                whileHover={{ 
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
                whileTap={{ scale: 0.98 }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Typography 
                    variant="h4" 
                    gutterBottom
                    sx={{
                      fontSize: { xs: '1.5rem', sm: '1.75rem' },
                      mb: 2,
                      background: 'linear-gradient(45deg, #64DFDF 30%, #B298DC 90%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    {app.title}
                  </Typography>
                  <Typography 
                    variant="body1" 
                    color="text.secondary"
                    sx={{ 
                      mb: 3,
                      lineHeight: 1.7,
                    }}
                  >
                    {app.description}
                  </Typography>
                  <motion.a
                    href={app.appStoreLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    style={{ display: 'inline-block' }}
                  >
                    <img 
                      src="/MacAppStoreBadge.svg" 
                      alt="Download on the Mac App Store" 
                      style={{
                        height: '40px',
                        filter: 'drop-shadow(0 4px 12px rgba(100, 223, 223, 0.2))',
                      }}
                    />
                  </motion.a>
                </CardContent>
              </MotionCard>
            </Grid>
          ))}
        </MotionGrid>
      </Container>
    </PageLayout>
  );
}

export default Apps;
