import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container, ThemeProvider, CssBaseline, Box } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { theme } from './theme';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Apps from './pages/Apps';
import Contact from './pages/Contact';
import Impressum from './pages/Impressum';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NotFound from './pages/NotFound';
import ErrorBoundary from './components/ErrorBoundary';
import { AnimatePresence } from 'framer-motion';

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Navbar />
            <Box
              component="main"
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                width: '100%',
              }}
            >
              <Container 
                sx={{ 
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  py: { xs: 2, sm: 4 },
                }}
              >
                <AnimatePresence mode="wait">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/apps" element={<Apps />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </AnimatePresence>
              </Container>
            </Box>
            <Footer />
          </Box>
        </ErrorBoundary>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
