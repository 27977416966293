import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#64DFDF',
    },
    secondary: {
      main: '#B298DC',
    },
    background: {
      default: '#080B14',
      paper: 'rgba(20, 24, 36, 0.8)',
    },
    text: {
      primary: '#E2E8F0',
      secondary: 'rgba(226, 232, 240, 0.6)',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
      letterSpacing: '0.01em',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      letterSpacing: '0.01em',
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.7,
    },
    button: {
      fontWeight: 500,
      letterSpacing: '0.02em',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
        
        @keyframes gradientBG {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        
        body {
          background-color: #080B14;
          background: linear-gradient(
            135deg,
            #080B14 0%,
            #0F172A 25%,
            #121832 50%,
            #0F172A 75%,
            #080B14 100%
          );
          background-size: 400% 400%;
          animation: gradientBG 15s ease infinite;
          min-height: 100vh;

          &:before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: radial-gradient(circle at 50% 50%, rgba(100, 223, 223, 0.03) 0%, transparent 70%);
            pointer-events: none;
            z-index: 1;
          }
        }

        ::selection {
          background: rgba(100, 223, 223, 0.2);
          color: #ffffff;
        }

        * {
          transition: all 0.2s ease-in-out;
        }
      `,
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(8, 11, 20, 0.7)',
          backdropFilter: 'blur(10px)',
          borderBottom: '1px solid rgba(226, 232, 240, 0.05)',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 20px rgba(100, 223, 223, 0.2)',
          },
        },
        contained: {
          background: 'linear-gradient(135deg, #64DFDF 0%, #48B4B4 100%)',
          '&:hover': {
            background: 'linear-gradient(135deg, #64DFDF 20%, #48B4B4 120%)',
          },
        },
        outlined: {
          borderColor: '#64DFDF',
          '&:hover': {
            borderColor: '#64DFDF',
            backgroundColor: 'rgba(100, 223, 223, 0.08)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(20, 24, 36, 0.8)',
          backdropFilter: 'blur(10px)',
          borderRadius: 16,
          border: '1px solid rgba(226, 232, 240, 0.05)',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            transform: 'translateY(-4px) scale(1.01)',
            boxShadow: '0 12px 40px rgba(100, 223, 223, 0.15)',
            border: '1px solid rgba(100, 223, 223, 0.1)',
          },
        },
      },
    },
  },
}); 