import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  Box,
  Paper,
  Button,
} from '@mui/material';
import { motion } from 'framer-motion';
import PageLayout from '../components/PageLayout';
import { itemVariants, chromaticText } from '../styles/effects';
import SEO from '../components/SEO';
import EmailIcon from '@mui/icons-material/Email';

const ChromaticTypography = ({ children, ...props }) => (
  <Typography
    {...props}
    component="div"
    sx={{
      ...chromaticText,
      ...props.sx,
    }}
    data-text={children}
  >
    {children}
  </Typography>
);

const MotionPaper = motion(Paper);

function Contact() {
  const [showEmail, setShowEmail] = useState(false);
  
  // Email parts split to prevent easy scraping
  const emailParts = {
    user: 'mail',
    domain: 'lohrsoftware',
    tld: 'com'
  };

  const handleRevealEmail = () => {
    setShowEmail(true);
  };

  return (
    <PageLayout>
      <SEO 
        title="Contact Us"
        description="Get in touch with us for any questions or support"
      />
      <Container maxWidth="md" sx={{ py: { xs: 4, md: 8 } }}>
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <motion.div variants={itemVariants}>
            <ChromaticTypography
              variant="h1"
              sx={{
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                mb: 2,
              }}
            >
              Contact Us
            </ChromaticTypography>
          </motion.div>
          <motion.div variants={itemVariants}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '1.1rem', sm: '1.25rem' },
                color: 'text.secondary',
                maxWidth: '600px',
                mx: 'auto',
                mb: 4,
              }}
            >
              Have questions? We'd love to hear from you.
            </Typography>
          </motion.div>
        </Box>

        <MotionPaper
          variants={itemVariants}
          sx={{
            p: { xs: 3, md: 4 },
            background: 'rgba(255, 255, 255, 0.03)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.05)',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '500px',
            mx: 'auto',
          }}
        >
          <Box sx={{ mb: 3 }}>
            <EmailIcon sx={{ fontSize: 48, color: 'primary.main' }} />
          </Box>
          <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
            Email us at
          </Typography>
          <Box>
            {showEmail ? (
              <Typography
                variant="h6"
                sx={{ 
                  color: 'primary.main',
                  fontFamily: 'monospace',
                  fontSize: '1.2rem',
                }}
              >
                {`${emailParts.user}@${emailParts.domain}.${emailParts.tld}`}
              </Typography>
            ) : (
              <Button
                variant="contained"
                onClick={handleRevealEmail}
                sx={{
                  px: 4,
                  py: 1.5,
                  fontSize: '1.1rem',
                }}
              >
                Click to Show Email
              </Button>
            )}
          </Box>
        </MotionPaper>
      </Container>
    </PageLayout>
  );
}

export default Contact;
