import React from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Container,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import SEO from '../components/SEO';
import { chromaticText, crtEffect, containerVariants, itemVariants } from '../styles/effects';

const MotionContainer = motion(Container);

const ChromaticTypography = ({ children, ...props }) => (
  <Typography
    {...props}
    component="div"
    sx={{
      ...chromaticText,
      ...props.sx,
    }}
    data-text={children}
  >
    {children}
  </Typography>
);

function Home() {
  return (
    <Box sx={{ ...crtEffect }}>
      <SEO />
      
      {/* Hero Section */}
      <MotionContainer
        maxWidth="lg"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <Box
          sx={{
            pt: { xs: 8, md: 12 },
            pb: { xs: 6, md: 8 },
            textAlign: 'center',
          }}
        >
          <motion.div variants={itemVariants}>
            <ChromaticTypography
              variant="h1"
              sx={{
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                mb: 3,
              }}
            >
              Lohr Software
            </ChromaticTypography>
          </motion.div>

          <motion.div variants={itemVariants}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '1.1rem', sm: '1.25rem' },
                mb: 5,
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
                lineHeight: 1.6,
              }}
            >
              Welcome to Lohr Software! We create high-quality, easy-to-use apps for Apple platforms. Our passion is to deliver the best user experience and utility for everyone using the macOS and iOS ecosystem.
            </Typography>
          </motion.div>

          <motion.div variants={itemVariants}>
            <Button
              component={Link}
              to="/apps"
              variant="contained"
              size="large"
              sx={{
                px: 4,
                py: 1.5,
              }}
            >
              View Our Apps
            </Button>
          </motion.div>
        </Box>
      </MotionContainer>
    </Box>
  );
}

export default Home;
